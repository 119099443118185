import { useEffect, type FC } from 'react';

import LimitsCurrencyMultiplier from '@/components/configurations/limits-currency-multiplier/LimitsCurrencyMultiplier';
import FiscalData from '@/components/configurations/other/FiscalData/FiscalData';
import CountryBusinessAccounts from '@/components/configurations/country-business-accounts/CountryBusinessAccounts';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { getCurrencyMultiplier } from '@/lib/redux/slices/limits-currency-multiplier/actions';
import { getFiscalData } from '@/lib/redux/slices/fiscal-data/actions';
import { getCountryBusinessAccounts } from '@/lib/redux/slices/country-business-accounts/actions';
import { resetFiscalData } from '@/lib/redux/slices/fiscal-data/slice';
import { reset as resetCurrencyMultiplier } from '@/lib/redux/slices/limits-currency-multiplier/slice';
import { reset as resetCountryBusinessAccounts } from '@/lib/redux/slices/country-business-accounts/slice';
import { Permissions } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';

const Other: FC = () => {
  const { country, isLoading } = useAppSelector(state => state.country);
  const dispatch = useAppDispatch();
  const hasEditPermission = usePermissions(Permissions.countryConfigEdit);

  useEffect(() => {
    if (!isLoading && country?.countryCode) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      Promise.all([
        dispatch(getCurrencyMultiplier({ currencyCode: country.currencies.find(c => c.isLocal)?.currencyCode ?? '' })),
        dispatch(getFiscalData({ countryCode: country.countryCode })),
        dispatch(getCountryBusinessAccounts({ countryCode: country.countryCode })),
      ]);
    }

    return () => {
      dispatch(resetCurrencyMultiplier());
      dispatch(resetFiscalData());
      dispatch(resetCountryBusinessAccounts());
    };
  }, [dispatch, isLoading, country?.countryCode, country?.currencies]);

  return (
    <div>
      <LimitsCurrencyMultiplier canEdit={hasEditPermission ?? false} />
      <FiscalData canEdit={hasEditPermission ?? false} />
      <CountryBusinessAccounts />
    </div>
  );
};

export default Other;

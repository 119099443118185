import { type CurrencyMultiplier } from '@/types/Limits';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createCurrencyMultiplier, getCurrencyMultiplier, updateCurrencyMultiplier } from './actions';

export interface CurrencyMultiplierSliceState {
  currencyMultiplier: CurrencyMultiplier['multiplier'] | null;
  currencyCode: CurrencyMultiplier['currencyCode'] | null;
  loading: boolean;
  isError: boolean;
}

const initialState: CurrencyMultiplierSliceState = {
  currencyMultiplier: null,
  currencyCode: null,
  loading: true,
  isError: false,
};

export const limitsCurrencyMultiplierSlice = createSlice({
  name: 'limitsCurrencyMultiplier',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(getCurrencyMultiplier.pending, state => {
      state.loading = true;
      state.isError = false;
    });
    builder.addCase(getCurrencyMultiplier.fulfilled, (state, action: PayloadAction<CurrencyMultiplier>) => {
      state.currencyMultiplier = action.payload ? action.payload.multiplier : null;
      state.currencyCode = action.payload ? action.payload.currencyCode : null;
      state.loading = false;
    });
    builder.addCase(getCurrencyMultiplier.rejected, state => {
      state.loading = false;
      state.currencyMultiplier = null;
      state.isError = true;
    });

    builder.addCase(createCurrencyMultiplier.pending, state => {
      state.loading = true;
      state.isError = false;
    });
    builder.addCase(createCurrencyMultiplier.fulfilled, (state, action: PayloadAction<CurrencyMultiplier>) => {
      state.currencyMultiplier = action.payload ? action.payload.multiplier : null;
      state.currencyCode = action.payload ? action.payload.currencyCode : null;
      state.loading = false;
    });
    builder.addCase(createCurrencyMultiplier.rejected, state => {
      state.loading = false;
      state.currencyMultiplier = null;
      state.isError = true;
    });

    builder.addCase(updateCurrencyMultiplier.pending, state => {
      state.loading = true;
      state.isError = false;
    });
    builder.addCase(updateCurrencyMultiplier.fulfilled, (state, action: PayloadAction<CurrencyMultiplier>) => {
      state.currencyMultiplier = action.payload ? action.payload.multiplier : null;
      state.currencyCode = action.payload ? action.payload.currencyCode : null;
      state.loading = false;
    });
    builder.addCase(updateCurrencyMultiplier.rejected, state => {
      state.loading = false;
      state.currencyMultiplier = null;
      state.isError = true;
    });
  },
});

export const { reset } = limitsCurrencyMultiplierSlice.actions;

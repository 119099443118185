import { createSlice } from '@reduxjs/toolkit';

import { type PaymentMethodGroup } from '@/types/PaymentMethodGroup';
import { type FormModalMode } from '@/utils/interfaces';

import { getPaymentMethodGroupTopup, createTopupPaymentMethodGroup, updateTopupPaymentMethodGroup } from './actions';

interface ModalState {
  mode: FormModalMode;
  data: PaymentMethodGroup;
  success: boolean;
  loading: boolean;
}

interface PaymentMethodGroupTopupState {
  paymentMethodGroups: PaymentMethodGroup[];
  loading: boolean;
  success: boolean;
  modal: ModalState;
}

const emptyGroup: PaymentMethodGroup = {
  icon: '',
  nameTranslationKey: '',
  countryCode: '',
  isEnabled: false,
  descriptionTranslationKey: '',
  order: 0,
  reason: '',
};

const initialModalState: ModalState = {
  mode: 'closed',
  data: emptyGroup,
  success: false,
  loading: false,
};

const initialState: PaymentMethodGroupTopupState = {
  paymentMethodGroups: [],
  loading: false,
  success: false,
  // Stores modal state and relevant modal data
  modal: initialModalState,
};

export const paymentMethodGroupTopupSlice = createSlice({
  name: 'paymentMethodGroupTopup',
  initialState,
  reducers: {
    setModalData(
      state,
      action: {
        payload: PaymentMethodGroup;
      },
    ) {
      state.modal.data = action.payload;
    },
    openModal(
      state,
      action: {
        payload: {
          mode: Exclude<FormModalMode, 'close'>;
          data?: PaymentMethodGroup;
        };
      },
    ) {
      state.modal.mode = action.payload.mode;
      if (action.payload.data) {
        state.modal.data = action.payload.data;
      }
    },
    // Use in case we need to just close the modal and keep modal values
    closeModal(state) {
      state.modal.mode = 'closed';
    },
    closeAndResetModal(state) {
      state.modal = initialModalState;
    },
    reset(state) {
      return {
        paymentMethodGroups: state.paymentMethodGroups,
        loading: false,
        success: false,
        modal: initialModalState,
      };
    },
  },
  extraReducers: builder => {
    // Load Payment Method Groups
    builder.addCase(getPaymentMethodGroupTopup.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethodGroupTopup.fulfilled, (state, action) => {
      state.paymentMethodGroups = action.payload;
      state.loading = false;
    });
    builder.addCase(getPaymentMethodGroupTopup.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    // Create Payment Method Group
    builder.addCase(createTopupPaymentMethodGroup.pending, state => {
      state.modal.loading = true;
      state.modal.success = false;
    });
    builder.addCase(createTopupPaymentMethodGroup.fulfilled, state => {
      state.modal = {
        ...initialModalState,
        success: true,
      };
    });
    builder.addCase(createTopupPaymentMethodGroup.rejected, state => {
      state.modal.loading = false;
      state.modal.success = false;
    });
    // Update Payment Method Group
    builder.addCase(updateTopupPaymentMethodGroup.pending, state => {
      state.modal.loading = true;
      state.modal.success = false;
    });
    builder.addCase(updateTopupPaymentMethodGroup.fulfilled, state => {
      state.modal = {
        ...initialModalState,
        success: true,
      };
    });
    builder.addCase(updateTopupPaymentMethodGroup.rejected, state => {
      state.modal.loading = false;
      state.modal.success = false;
    });
  },
});

export const { setModalData, openModal, closeAndResetModal, reset } = paymentMethodGroupTopupSlice.actions;

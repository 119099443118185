import { useCallback, type FC, useState, useMemo } from 'react';
import { Select } from 'k8-web-lib-tmp';
import { type Option } from '@/utils/interfaces';
import { useAppSelector } from '@/lib/redux';

interface GroupSelectProps {
  value?: string;
  onChange: (value?: string) => void;
}

const NO_GROUP_OPTION: Option = {
  text: 'No group',
};

export const GroupSelect: FC<GroupSelectProps> = ({ value, onChange }) => {
  const { loading, paymentMethodGroups } = useAppSelector(state => state.paymentMethodGroupTopup);

  const options: Option[] = useMemo(() => {
    return paymentMethodGroups.reduce(
      (acc, group) => {
        acc.push({
          value: group.id,
          text: group.nameTranslationKey,
        });
        return acc;
      },
      [NO_GROUP_OPTION],
    );
  }, [paymentMethodGroups]);

  const [selectedOption, setSelectedOption] = useState(options.find(e => e.value === value) ?? NO_GROUP_OPTION);

  // DIG-13375 will ensure proper handling of group loading when modifying the URL logic
  const onSelect = useCallback(
    (option: Option) => {
      setSelectedOption(option);
      onChange(option.value);
    },
    [onChange],
  );

  return (
    <Select
      options={options}
      selected={selectedOption}
      onSelect={onSelect}
      loading={loading}
    />
  );
};

export default GroupSelect;

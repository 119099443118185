import authAxios from '@/auth/axios';
import { type FiscalData } from '@/types/FiscalData';
import { errorToast, successToast } from '@/utils/toastMessage';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getFiscalData = createAsyncThunk(
  'fiscalData.getFiscalData',
  async ({ countryCode }: { countryCode: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: FiscalData[] }>(`/fiscal-number-types/${countryCode}`);
      return data.data[0];
    } catch (err: any) {
      return rejectWithValue(err.response?.data || 'Error fetching fiscal data');
    }
  },
);

export const addFiscalData = createAsyncThunk(
  'fiscalData.addFiscalData',
  async ({ countryCode, name, regex }: { countryCode: string; name: string; regex: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.post<{ data: FiscalData }>('/fiscal-number-types', {
        countryCode,
        name,
        regex,
      });
      successToast('toast-messages.create-fiscal-data-success');
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updateFiscalData = createAsyncThunk(
  'fiscalData.updateFiscalData',
  async ({ id, name, regex }: { id: string; name: string; regex: string }, { rejectWithValue }) => {
    try {
      await authAxios.patch<{ data: { name: string; regex: string } }>(`/fiscal-number-types/${id}`, {
        name,
        regex,
      });
      successToast('toast-messages.update-fiscal-data-success');
      return { name, regex };
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

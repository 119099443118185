import { createSlice } from '@reduxjs/toolkit';

import { type PaymentMethod } from '@/types/PaymentMethod';
import { type FormModalMode } from '@/utils/interfaces';

import { getPaymentMethodTopupByGroup, createPaymentMethodTopup, updatePaymentMethodTopup } from './actions';

interface ModalState {
  mode: FormModalMode;
  data: PaymentMethod;
  success: boolean;
  loading: boolean;
}

interface PaymentMethodTopupState {
  paymentMethods: PaymentMethod[];
  loading: boolean;
  success: boolean;
  modal: ModalState;
  totalCount: number;
  page: number;
  itemsPerPage: number;
}

export const emptyMethod: PaymentMethod = {
  name: '',
  nameTranslationKey: '',
  groupId: undefined,
  countryCode: '',
  isEnabled: false,
  imageUrl: '',
  channelId: '',
  descriptionTranslationKey: '',
  order: 0,
  minAmount: 0,
  minAmountCurrency: '',
  reason: '',
  allowInCheckout: false,
  identityNumberSource: '',
};

const initialModalState: ModalState = {
  mode: 'closed',
  data: emptyMethod,
  success: false,
  loading: false,
};

const initialState: PaymentMethodTopupState = {
  paymentMethods: [],
  loading: false,
  success: false,
  itemsPerPage: -1,
  // Stores modal state and relevant modal data
  modal: initialModalState,
  totalCount: 0,
  page: -1,
};

export const paymentMethodTopupSlice = createSlice({
  name: 'paymentMethodTopup',
  initialState,
  reducers: {
    setModalData(
      state,
      action: {
        payload: PaymentMethod;
      },
    ) {
      state.modal.data = action.payload;
    },
    openModal(
      state,
      action: {
        payload: {
          mode: Exclude<FormModalMode, 'close'>;
          data?: PaymentMethod;
        };
      },
    ) {
      state.modal.mode = action.payload.mode;
      if (action.payload.data) {
        state.modal.data = action.payload.data;
      }
    },
    // Use in case we need to just close the modal and keep modal values
    closeModal(state) {
      state.modal.mode = 'closed';
    },
    closeAndResetModal(state) {
      state.modal = initialModalState;
    },
    reset() {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPaymentMethodTopupByGroup.pending, state => {
      state.loading = true;
    });
    builder.addCase(getPaymentMethodTopupByGroup.fulfilled, (state, action) => {
      state.paymentMethods = action.payload.paymentMethods;
      state.totalCount = action.payload.totalCount;
      state.page = action.payload.page;
      state.itemsPerPage = action.payload.itemsPerPage;
      state.loading = false;
    });
    builder.addCase(getPaymentMethodTopupByGroup.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    // Create Payment Method
    builder.addCase(createPaymentMethodTopup.pending, state => {
      state.modal.loading = true;
      state.modal.success = false;
    });
    builder.addCase(createPaymentMethodTopup.fulfilled, state => {
      state.modal = {
        ...initialModalState,
        success: true,
      };
    });
    builder.addCase(createPaymentMethodTopup.rejected, state => {
      state.modal.loading = false;
      state.modal.success = false;
    });
    // Update Payment Method Group
    builder.addCase(updatePaymentMethodTopup.pending, state => {
      state.modal.loading = true;
      state.modal.success = false;
    });
    builder.addCase(updatePaymentMethodTopup.fulfilled, state => {
      state.modal = {
        ...initialModalState,
        success: true,
      };
    });
    builder.addCase(updatePaymentMethodTopup.rejected, state => {
      state.modal.loading = false;
      state.modal.success = false;
    });
  },
});

export const { setModalData, openModal, closeAndResetModal, reset } = paymentMethodTopupSlice.actions;

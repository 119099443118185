import { useState, useCallback, useEffect, type FC } from 'react';
import { GridInputRow } from './styles';
import Input from '@/components/common/inputs/Input';
import {
  LimitsByPeriodTypeEnum,
  TransactionTypeEnum,
  MoneyFlowTypeEnum,
  type LimitsResponse,
  type LimitsByPeriodType,
  type LimitsRequestBodyData,
  type LimitsByPeriod,
} from '@/types/Limits';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { createLimit, updateLimit } from '@/lib/redux/slices/limits/actions';
import { type PaymentMethod } from '@/types/PaymentMethod';
import { groupLimitsByPeriodType } from '@/utils/limits';
import { Permissions } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';
import { parseNumberOrDefault } from '@/utils/helperFunctions';

interface TopUpInputProps {
  type: string;
  method: PaymentMethod;
  limits?: LimitsResponse[];
}

const TopUpInput: FC<TopUpInputProps> = props => {
  const { type, method, limits } = props;

  const dispatch = useAppDispatch();

  const hasCountryEditPermission = usePermissions(Permissions.countryConfigEdit);

  const { selectedLevel } = useAppSelector(state => state.limits);

  const [topupByPeriodType, setTopupByPeriodType] = useState<LimitsByPeriod>();

  const [hasChanged, setHasChanged] = useState(false);

  useEffect(() => {
    if (limits) {
      setTopupByPeriodType(groupLimitsByPeriodType(limits));
    }
  }, [limits]);

  const handleInputBlur = useCallback(
    (periodType: LimitsByPeriodType) => {
      const limitData = topupByPeriodType?.[periodType];
      if (limitData && limitData?.value >= 0 && hasChanged) {
        if (limitData.id === undefined) {
          const requestBodyData: LimitsRequestBodyData = {
            limitConfigurations: [
              {
                channelId: method.channelId,
                limitLevel: selectedLevel?.text || '',
                periodType,
                value: Number(limitData.value),
                limitType: 'Amount',
                moneyFlowType: MoneyFlowTypeEnum.MoneyIn,
                countryCode: method.countryCode,
              },
            ],
          };
          void dispatch(createLimit({ data: requestBodyData, transactionType: TransactionTypeEnum.Topup }));
        } else {
          void dispatch(
            updateLimit({
              data: { value: Number(limitData?.value) },
              limitId: limitData.id || '',
              transactionType: TransactionTypeEnum.Topup,
            }),
          );
        }

        setHasChanged(false);
      }
    },
    [dispatch, method.countryCode, method.channelId, selectedLevel, topupByPeriodType, hasChanged],
  );

  const updateTransferLimit = useCallback(
    (periodType: LimitsByPeriodType, value: string) => {
      setTopupByPeriodType({
        ...topupByPeriodType,
        [periodType]: {
          ...topupByPeriodType?.[periodType],
          value: parseNumberOrDefault(value, topupByPeriodType?.[periodType]?.value || 0),
        },
      });
      setHasChanged(true);
    },
    [topupByPeriodType],
  );

  if (!topupByPeriodType) {
    return null;
  }

  return (
    <GridInputRow>
      <div className='type-cell'>{type}</div>
      <div className='transaction-cell'>
        <Input
          value={topupByPeriodType?.byTransaction?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.ByTransaction, value)}
          onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.ByTransaction)}
          disabled={!hasCountryEditPermission}
        />
      </div>
      <div className='daily-cell'>
        <Input
          value={topupByPeriodType?.daily?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.Daily, value)}
          onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.Daily)}
          disabled={!hasCountryEditPermission}
        />
      </div>
      <div className='weekly-cell'>
        <Input
          value={topupByPeriodType?.weekly?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.Weekly, value)}
          onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.Weekly)}
          disabled={!hasCountryEditPermission}
        />
      </div>
      <div className='monthly-cell'>
        <Input
          value={topupByPeriodType?.monthly?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.Monthly, value)}
          onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.Monthly)}
          disabled={!hasCountryEditPermission}
        />
      </div>
      <div className='lifetime-cell'>
        <Input
          value={topupByPeriodType?.lifetime?.value.toString() || ''}
          onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.Lifetime, value)}
          onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.Lifetime)}
          disabled={!hasCountryEditPermission}
        />
      </div>
    </GridInputRow>
  );
};

export default TopUpInput;

import { ClickAwayListener } from '@mui/base';
import styles from './FiltersModal.module.scss';
import { mediumGrey } from '@/utils/colors';
import CrossIcon from '@/components/icons/CrossIcon';
import { createPortal } from 'react-dom';
import Typography from '../typography/Typography';
import { useTranslation } from 'react-i18next';
import LinkButton from '../button/LinkButton';

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  clearAllFilters: () => void;
  disabledClearAll?: boolean;
}

export default function FiltersModal({
  children,
  onClose,
  clearAllFilters,
  disabledClearAll,
}: Readonly<Props>): React.JSX.Element {
  const { t } = useTranslation('global');
  return createPortal(
    <div className={styles.wrapper}>
      <ClickAwayListener onClickAway={onClose}>
        <div className={styles.content}>
          <button
            className={styles.closeIcon}
            onClick={onClose}
          >
            <CrossIcon
              width={`${20 / 16}rem`}
              height={`${20 / 16}rem`}
              color={mediumGrey.cssColor}
              thickness='1.5'
            />
          </button>
          <Typography variant='p0Bold'>{t('filters.title')}</Typography>
          <LinkButton
            onClick={clearAllFilters}
            disabled={disabledClearAll}
          >
            {t('filters.clear-all')}
          </LinkButton>
          <div className={styles.childrenContainer}>{children}</div>
        </div>
      </ClickAwayListener>
    </div>,
    document.body,
  );
}

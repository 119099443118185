import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@/components/common/typography/Typography';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import InputLabel from '@/components/common/labels/InputLabel';
import { type RoleOption, useUserRoleOptions } from '@/utils/hooks/useUserRoleOptions';
import Input from '@/components/common/inputs/Input';
import { type Role, updateUserDetails } from '@/lib/redux/slices/user-edit/actions';
import { reset } from '@/lib/redux/slices/user-edit/slice';
import { fetchUsers } from '@/lib/redux/slices/users/actions';
import { type RootState, useAppDispatch, useAppSelector } from '@/lib/redux';
import styles from './ChangeRoleModal.module.scss';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';
import MultiSelectDropdown from '@/components/common/filters/MultiSelectDropdown';
import { type UserRole } from '@/types';

interface RoleModalProps {
  email: string;
  currentRoles: UserRole[];
  userId: string;
  currentPage: number;
  onClose: () => void;
}

const ChangeRoleModal = ({ email, currentRoles, userId, currentPage, onClose }: RoleModalProps): JSX.Element => {
  const { t } = useTranslation(['accessManagement', 'global']);

  const { loading, success } = useAppSelector((state: RootState) => state.updateUserDetailsSlice);
  const [itemsPerPage] = useItemsPerPage();

  const roleOptions = useUserRoleOptions();

  const dispatch = useAppDispatch();

  const [selectedRoles, setSelectedRoles] = useState<RoleOption[]>(
    currentRoles.map(role => ({
      text: role.roleName,
      role,
    })),
  );

  const [reason, setReason] = useState('');

  const handleCloseActionModal = (): void => {
    onClose();
  };

  useEffect(() => {
    if (success) {
      onClose();
      dispatch(reset());
      void dispatch(fetchUsers({ page: currentPage, itemsPerPage }));
    }
  }, [success, onClose, dispatch, itemsPerPage, currentPage]);

  const handleConfirm = (): void => {
    const roleList: Role[] = selectedRoles.map(role => {
      return {
        roleId: role?.role.roleID ?? '',
        roleName: role?.role.roleName ?? '',
        comment: role.role.comment ?? '',
        reason,
      };
    });

    void dispatch(
      updateUserDetails({
        userId,
        body: {
          roles: roleList,
        },
      }),
    );
  };

  const emailLabel: string = t('tabs.users.change-role-modal.email-label');
  const currentRoleLabel: string = t('tabs.users.change-role-modal.current-roles');

  return (
    <ActionModal
      title={t('tabs.users.change-role-modal.title')}
      titleVariant='p0Bold'
      disabledButton={loading || reason.trim().length === 0 || selectedRoles.length === 0}
      confirmButtonLabel={t('confirm-modal.confirm', { ns: 'global' })}
      closeButtonLabel={t('confirm-modal.cancel', { ns: 'global' })}
      onClose={handleCloseActionModal}
      onConfirm={handleConfirm}
    >
      <div className={styles.labels}>
        <Typography variant='p1RegularBold'>{`${emailLabel}: ${email}`}</Typography>
        {selectedRoles.length > 0 && (
          <Typography variant='p1RegularBold'>{`${currentRoleLabel}: ${selectedRoles.map(x => x.text).join(', ')}`}</Typography>
        )}
      </div>
      <div className={styles.inputBox}>
        <InputLabel label={t('tabs.users.change-role-modal.select-roles')}>
          <MultiSelectDropdown
            options={roleOptions}
            selectedOptions={selectedRoles ?? []}
            onChange={roles => setSelectedRoles(roles as RoleOption[])}
            excludeAll={true}
          />
        </InputLabel>
        <InputLabel label={t('tabs.users.change-role-modal.reason')}>
          <Input
            value={reason}
            onChange={newReason => setReason(newReason)}
          />
        </InputLabel>
      </div>
    </ActionModal>
  );
};

export default ChangeRoleModal;

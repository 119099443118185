import { type FC, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppSelector, useAppDispatch } from '@/lib/redux';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import Accordion from '@/components/common/accordion/Accordion';
import { AccordionTitle } from '@/components/configurations';
import Button from '@/components/common/button/Button';
import styles from './FiscalData.module.scss';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';
import { addFiscalData, updateFiscalData } from '@/lib/redux/slices/fiscal-data/actions';
import { objectDeepEquality } from '@/utils/helperFunctions';

const GridItem = styled.div<{ $cell?: [number?, number?]; $align?: string }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $align }) => $align ?? 'center'};
  align-content: center;
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;

export interface FiscalDataProps {
  canEdit: boolean;
}

const FiscalData: FC<FiscalDataProps> = ({ canEdit }) => {
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();

  const { countryCode } = useAppSelector(({ country: { country } }) => country);
  const { fiscalData: data } = useAppSelector(state => state.fiscalData);

  const [isChanged, setIsChanged] = useState(false);
  const [fiscalData, setFiscalData] = useState(data);
  const { fiscalDataRegExp, fiscalDataType } = fiscalData;
  const [active, setActive] = useState(false);

  const onSubmit = (): void => {
    if (data.id.length === 0) {
      void dispatch(addFiscalData({ countryCode, name: fiscalDataType, regex: fiscalDataRegExp }));
    } else {
      void dispatch(updateFiscalData({ id: data.id, name: fiscalDataType, regex: fiscalDataRegExp }));
    }
  };

  useEffect(() => {
    setFiscalData(data);
  }, [data]);

  useEffect(() => {
    const isNew = data.fiscalDataType.length === 0;
    const isEqual = objectDeepEquality(data, fiscalData);
    setIsChanged(!isNew && isEqual);
  }, [data, fiscalData]);

  const isSubmitDisabled = isChanged || fiscalDataRegExp.length === 0 || fiscalDataType.length === 0;

  const renderContent = (
    <div className={styles.card}>
      <div className={styles.gridCard}>
        <GridItem $cell={[1, 1]}>
          <InputLabel label={t('fiscal-data.country-code')}>
            <Input
              value={countryCode}
              disabled={true}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[2, 1]}>
          <InputLabel label={t('fiscal-data.fiscal-data-type')}>
            <Input
              value={fiscalDataType}
              disabled={!canEdit}
              onChange={(value: string) => setFiscalData(fData => ({ ...fData, fiscalDataType: value }))}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[3, 1]}>
          <InputLabel label={t('fiscal-data.fiscal-data-regex')}>
            <Input
              value={fiscalDataRegExp}
              onChange={(value: string) => setFiscalData(fData => ({ ...fData, fiscalDataRegExp: value }))}
              disabled={!canEdit}
            />
          </InputLabel>
        </GridItem>
      </div>
      <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
        <div className={styles.buttons}>
          <Button
            textTransform='uppercase'
            disabled={isSubmitDisabled}
            onClick={onSubmit}
          >
            {t('new-country.button-submit-configuration')}
          </Button>
        </div>
      </UserPermissionsGuard>
    </div>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          title={t('fiscal-data.title')}
          isValid={isChanged}
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
};

export default FiscalData;

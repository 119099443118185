import { type BusinessAccounts } from '@/types';
import { type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { createBusinessAccounts, getCountryBusinessAccounts } from './actions';

export interface AccountsSliceState {
  businessAccounts?: BusinessAccounts[] | null;
  loading: boolean;
  isError: boolean;
}

const initialState: AccountsSliceState = {
  businessAccounts: undefined,
  loading: true,
  isError: false,
};

export const countryBusinessAccountsSlice = createSlice({
  name: 'countryBusinessAccounts',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    builder.addCase(getCountryBusinessAccounts.pending, state => {
      state.loading = true;
      state.isError = false;
    });
    builder.addCase(getCountryBusinessAccounts.fulfilled, (state, action: PayloadAction<BusinessAccounts[]>) => {
      state.businessAccounts = action.payload;
      state.loading = false;
    });
    builder.addCase(getCountryBusinessAccounts.rejected, state => {
      state.loading = false;
      state.businessAccounts = null;
      state.isError = true;
    });
    builder.addCase(createBusinessAccounts.pending, state => {
      state.loading = true;
      state.isError = false;
    });
    builder.addCase(createBusinessAccounts.fulfilled, (state, action: PayloadAction<BusinessAccounts[]>) => {
      state.businessAccounts = action.payload;
      state.loading = false;
    });
    builder.addCase(createBusinessAccounts.rejected, state => {
      state.loading = false;
      state.businessAccounts = null;
      state.isError = true;
    });
  },
});

export const { reset } = countryBusinessAccountsSlice.actions;

import { type Channel } from '@/types/Channel';
import { type Option } from '@/utils/interfaces';

export interface LimitLevel {
  id: string;
  name: string;
}

export type LimitLevelType = 'Standard' | 'Advanced' | 'VIP' | 'Entry';

export type PeriodType = 'By Transaction' | 'Daily' | 'Weekly' | 'Monthly' | 'Annual' | 'Lifetime';

export type LimitsByPeriodType = 'byTransaction' | 'daily' | 'weekly' | 'monthly' | 'annualy' | 'lifetime';

export type LimitType = 'Amount' | 'Quantity';

export type MoneyFlowType = 'MoneyIn' | 'MoneyOut';

export type TransactionType = 'PaymentToCustomer' | 'Transfer' | 'Topup';

export interface LimitsRequestBodyData {
  limitConfigurations: [
    {
      id?: string;
      limitType: string;
      limitLevel: string;
      moneyFlowType: string;
      periodType: string;
      countryCode: string;
      channelId: string;
      value: number;
    },
  ];
}

export enum MoneyFlowTypeEnum {
  MoneyIn = 'MoneyIn',
  MoneyOut = 'MoneyOut',
}

export enum LimitTypeEnum {
  Amount = 'Amount',
  Quantity = 'Quantity',
}

export enum LimitLevelEnum {
  Standard = 'Standard',
  Advanced = 'Advanced',
  Vip = 'VIP',
  Entry = 'Entry',
}

export enum PeriodTypeEnum {
  ByTransaction = 'ByTransaction',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Annual = 'Annual',
  Lifetime = 'Lifetime',
}

export enum TransactionTypeEnum {
  PaymentToCustomer = 'PaymentToCustomer',
  Transfer = 'Transfer',
  Topup = 'Topup',
}

export enum LimitsByPeriodTypeEnum {
  ByTransaction = 'byTransaction',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Annual = 'annualy',
  Lifetime = 'lifetime',
}

export interface LimitChannelData {
  id?: string;
  name: string;
  transactionType: string;
}

export interface LimitsResponse {
  id?: string;
  limitType: string;
  limitLevel: string;
  moneyFlowType: string;
  periodType: string;
  countryCode: string;
  channelId: string;
  channel: LimitChannelData;
  value: number;
}

export interface LimitLevelsResponse {
  data: {
    data: LimitLevel[];
  };
}

export interface LimitsParams {
  periodType?: PeriodType;
  limitLevel?: LimitLevelType;
  limitType?: LimitType;
  moneyFlowType?: MoneyFlowType;
  countryCode?: string;
  channelId?: string;
}

export interface ChannelsSearchParams {
  transactionType?: TransactionTypeEnum;
}

export interface SearchChannelResponse {
  data: {
    data: Channel[];
  };
}

export interface PostData {
  limitConfigurations: [
    {
      id?: string;
      limitType: string;
      limitLevel: string;
      moneyFlowType: string;
      periodType: string;
      countryCode: string;
      channelId: string;
      value: number;
    },
  ];
}
export interface LimitsByPeriod {
  byTransaction?: LimitsResponse;
  daily?: LimitsResponse;
  weekly?: LimitsResponse;
  monthly?: LimitsResponse;
  annualy?: LimitsResponse;
  lifetime?: LimitsResponse;
}

export interface LimitsState {
  limitLevels?: LimitLevel[];
  selectedLevel?: Option | undefined;
  limits?: LimitsResponse[];
  paymentToCustomerLimits: LimitsResponse[];
  pToCChannels: Channel[];
  transferLimits: LimitsResponse[];
  transferChannels: Channel[];
  topup: LimitsResponse[];
  loading: boolean;
  success: boolean;
  errorFieldUpdate: boolean;
}

export interface PostResponse {
  data: [
    {
      id?: string;
      limitType: string;
      limitLevel: string;
      moneyFlowType: string;
      periodType: string;
      countryCode: string;
      channelId: string;
      value: number;
    },
  ];
}

export interface CurrencyMultiplier {
  currencyCode: string;
  multiplier: number;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@/components/common/typography/Typography';
import styles from './Headers.module.scss';
import { type Transaction } from '@/types';
import { type TransactionActionType } from './TransactionActions';
import { splitCapitalizedWords } from '@/utils/helperFunctions';

interface HeadersProps {
  transaction?: Transaction;
  action?: TransactionActionType;
}

const Headers: React.FC<HeadersProps> = ({ transaction, action }) => {
  const { t } = useTranslation(['customerOverview', 'global']);

  let amount;

  if (transaction?.requestedAmount && transaction?.requestedAmountCurrency) {
    amount = `${transaction.requestedAmount} ${transaction.requestedAmountCurrency}`;
  }

  return (
    <>
      <Typography
        style={{ marginBottom: '24px' }}
        variant='p0'
      >
        {t('widgets.transaction-history.modal-input-transaction-id', { id: transaction?.transactionId }) +
          (amount ? ` / ${amount}` : '')}
      </Typography>

      {action && (
        <div className={styles.transactionWrapper}>
          <Typography variant='p2Semibold'>{splitCapitalizedWords(action)}</Typography>
        </div>
      )}
    </>
  );
};

export default Headers;

import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { createBusinessAccounts } from '@/lib/redux/slices/country-business-accounts/actions';
import { type Currency, Permissions } from '@/types';
import Accordion from '@/components/common/accordion/Accordion';
import { AccordionTitle } from '@/components/configurations';
import Button from '@/components/common/button/Button';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import BusinessAccountRow from './BusinessAccountRow';

import styles from './CountryBusinessAccounts.module.scss';

const CountryBusinessAccounts = (): JSX.Element => {
  const { t } = useTranslation('configurations');

  const dispatch = useAppDispatch();
  const { country } = useAppSelector(state => state.country);
  const { businessAccounts, loading } = useAppSelector(state => state.countryBusinessAccounts);

  const [active, setActive] = useState(false);

  const createable = useMemo((): boolean => {
    return businessAccounts == null && country.currencies?.length > 0;
  }, [businessAccounts, country.currencies]);

  const onSubmit = (): void => {
    if (createable) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      dispatch(
        createBusinessAccounts({
          countryCode: country.countryCode,
          integratorId: `PayRetailers${country.countryName}`,
        }),
      );
    }
  };

  const checkSubmitDisabled = useMemo((): boolean => {
    return loading || !createable;
  }, [loading, createable]);

  const currencies = useMemo((): Currency[] => {
    return [...(country.currencies ?? [])].sort((a, b) => {
      if (a.isLocal === b.isLocal) return 0;
      return a.isLocal ? -1 : 1;
    });
  }, [country.currencies]);

  const renderContent = (
    <div className={styles.card}>
      <div className={styles.gridCard}>
        {currencies.map(currency => (
          <BusinessAccountRow
            key={currency.currencyCode}
            currency={currency}
            businessAccount={businessAccounts?.find(ba => ba.currencyCode === currency.currencyCode)}
          />
        ))}
      </div>
      <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
        {!businessAccounts && (
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              textTransform='uppercase'
              disabled={checkSubmitDisabled}
              onClick={() => onSubmit()}
            >
              {t('business-accounts.button-create-business-accounts')}
            </Button>
          </div>
        )}
      </UserPermissionsGuard>
    </div>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          title={t('business-accounts.title')}
          isValid={businessAccounts != null}
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
};

export default CountryBusinessAccounts;

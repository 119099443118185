import authAxios from '@/auth/axios';
import { type CurrencyMultiplier } from '@/types/Limits';
import { errorToast, successToast } from '@/utils/toastMessage';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCurrencyMultiplier = createAsyncThunk(
  'limitsCurrencyMultiplier.getCurrencyMultiplier',
  async ({ currencyCode }: { currencyCode: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: CurrencyMultiplier }>(`/limits/currency-multiplier/${currencyCode}`);
      return data.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createCurrencyMultiplier = createAsyncThunk(
  'limitsCurrencyMultiplier.createCurrencyMultiplier',
  async ({ currencyCode, multiplier }: { currencyCode: string; multiplier: number }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.post<{ data: CurrencyMultiplier }>('/limits/currency-multiplier', {
        currencyCode,
        multiplier,
      });
      successToast('toast-messages.create-limits-currency-multiplier-success');
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updateCurrencyMultiplier = createAsyncThunk(
  'limitsCurrencyMultiplier.updateCurrencyMultiplier',
  async ({ currencyCode, multiplier }: { currencyCode: string; multiplier: number }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.put<{ data: CurrencyMultiplier }>(
        `/limits/currency-multiplier/${currencyCode}`,
        {
          multiplier,
        },
      );
      successToast('toast-messages.update-limits-currency-multiplier-success');
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

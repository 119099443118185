import { toRem } from 'k8-web-lib-tmp';
import styled from 'styled-components';
import { normalWhite } from '@/utils/colors/white';
import { extraLightWhite, purple } from '@/utils/colors';

export const LimitLevelsLabel = styled.span`
  padding: 0 ${toRem(12)};
  font-size: ${toRem(13)};
  font-weight: 500;
`;

export const LimitLevelsContainer = styled.div`
  display: inline-block;
  margin-bottom: ${toRem(20)};
  min-width: ${toRem(165)};
`;

export const AccordeonTitleStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${toRem(20)};
`;

export const GridContainerHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: ${toRem(20)};
  background-color: ${extraLightWhite.cssColor};
  padding: ${toRem(8)};
  font-size: ${toRem(15)};
  font-weight: 500;
  color: ${purple.cssColor};
`;

export const GridContainer = styled.div`
  font-size: ${toRem(15)};
  padding-right: ${toRem(8)};
  font-weight: 500;
  color: ${purple.cssColor};

  .inner-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: ${toRem(20)};
    padding: ${toRem(8)} 0;
    border-bottom: ${toRem(1)} solid ${normalWhite.cssColor};
  }
`;

export const GridContainerTable = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: ${toRem(24)};
  padding: ${toRem(8)};
  font-size: ${toRem(15)};
  font-weight: 500;
  color: ${purple.cssColor};
`;

export const GridItem = styled.div<{ $cell?: [number?, number?]; $height?: string }>`
  display: flex;
  align-items: center;
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  gap: ${toRem(20)};
  padding: ${toRem(8)} 0;
`;

export const GridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: ${toRem(20)};
  grid-column: 1 / span 7;
  border-bottom: ${toRem(1)} solid ${normalWhite.cssColor};
  padding: ${toRem(13)} 0;
`;

export const GridCell = styled.div<{ $start: number; $span?: number }>`
  grid-column: ${({ $start, $span = 1 }) => `${$start} / span ${$span}`};
  display: flex;
  align-items: center;
`;

export const GridInputRow = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: ${toRem(20)};
  width: 100%;
  border-top: ${toRem(1)} solid ${normalWhite.cssColor};
  padding: ${toRem(12)} 0 ${toRem(2)};

  .type-cell {
    grid-column: 1 / span 2;
    padding-left: ${toRem(20)};
    display: flex;
    align-items: center;
  }

  .transaction-cell,
  .daily-cell,
  .weekly-cell,
  .monthly-cell,
  .lifetime-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .transaction-cell {
    grid-column: 3 / span 1;
  }
  .daily-cell {
    grid-column: 4 / span 1;
  }
  .weekly-cell {
    grid-column: 5 / span 1;
  }
  .monthly-cell {
    grid-column: 6 / span 1;
  }
  .lifetime-cell {
    grid-column: 7 / span 1;
  }
`;

import { type FC } from 'react';

import FilledCircleIcon from '@/components/icons/FilledCircleIcon';
import Typography from '@/components/common/typography/Typography';
import CircleTickIcon from '@/components/icons/CircleTickIcon';

import { AccordeonTitleStyled } from './styles';

interface MoneyInAccordeonTitleProps {
  title: string;
  isValid?: boolean;
}

const MoneyInAccordeonTitle: FC<MoneyInAccordeonTitleProps> = ({ title, isValid = false }) => {
  return (
    <AccordeonTitleStyled>
      {isValid && <CircleTickIcon />}
      {!isValid && <FilledCircleIcon />}
      <Typography variant='p2MediumTight'> {title}</Typography>
    </AccordeonTitleStyled>
  );
};

export default MoneyInAccordeonTitle;

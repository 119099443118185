import { createAsyncThunk } from '@reduxjs/toolkit';
import authAxios from '@/auth/axios';

import { errorToast, successToast } from '@/utils/toastMessage';
import {
  type LimitsParams,
  type ChannelsSearchParams,
  type SearchChannelResponse,
  type PostData,
  type LimitLevelsResponse,
  type TransactionTypeEnum,
  type LimitsResponse,
  type PostResponse,
} from '@/types/Limits';
import { COUNTRY_CONFIGURATION_LIMITS_CURRENCY } from '@/utils/constants';

export const getLimitLevels = createAsyncThunk('limits.getLimitLevels', async (_, { rejectWithValue }) => {
  try {
    const response = await authAxios.get<LimitLevelsResponse>('/limit-configurations/limit-levels');

    return response.data.data.data;
  } catch (err: any) {
    errorToast();
    return rejectWithValue(err);
  }
});

export const getLimits = createAsyncThunk('limits.getLimits', async (query: LimitsParams, { rejectWithValue }) => {
  try {
    const { data } = await authAxios.get<{ data: { limits: LimitsResponse[] } }>(
      `/limit-configurations/${COUNTRY_CONFIGURATION_LIMITS_CURRENCY}`,
      { params: query },
    );

    return data.data;
  } catch (err: any) {
    return rejectWithValue(err);
  }
});

export const searchChannels = createAsyncThunk(
  'limits.getChannels',
  async (query: ChannelsSearchParams, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<SearchChannelResponse>('/channels/search', { params: query });

      return { ...data.data, ...query };
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createLimit = createAsyncThunk(
  'limits.createLimit',
  async ({ data, transactionType }: { data: PostData; transactionType: TransactionTypeEnum }, { rejectWithValue }) => {
    try {
      const response = await authAxios.post<PostResponse>('/limit-configurations', data);

      successToast('toast-messages.limit-created');
      return { data: response.data, transactionType };
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updateLimit = createAsyncThunk(
  'limits.updateLimit',
  async (
    {
      data,
      limitId,
      transactionType,
    }: { data: { value: number }; limitId: string; transactionType: TransactionTypeEnum },
    { rejectWithValue },
  ) => {
    try {
      await authAxios.put<{ id: string; value: number }>(`/limit-configurations/${limitId}`, data);

      successToast('toast-messages.limit-updated');
      return {
        data,
        limitId,
        transactionType,
      };
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

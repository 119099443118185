import { type FiscalData } from '@/types/FiscalData';
import { createSlice } from '@reduxjs/toolkit';
import { getFiscalData, addFiscalData, updateFiscalData } from './actions';

export interface FiscalDataSliceState {
  fiscalData: FiscalData;
  isLoading: boolean;
  currencyCode: null;
  isError: boolean;
}

const initialState: FiscalDataSliceState = {
  fiscalData: {
    fiscalDataCountryCode: '',
    fiscalDataRegExp: '',
    fiscalDataType: '',
    id: '',
  },
  currencyCode: null,
  isLoading: true,
  isError: false,
};

export const fiscalDataSlice = createSlice({
  name: 'fiscalData',
  initialState,
  reducers: {
    resetFiscalData() {
      return { ...initialState };
    },
  },
  extraReducers: builder => {
    // get fiscal data
    builder.addCase(getFiscalData.pending, state => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(getFiscalData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fiscalData = action.payload;
    });
    builder.addCase(getFiscalData.rejected, state => {
      state.isLoading = false;
      state.isError = true;
    });
    // create fiscal data
    builder.addCase(addFiscalData.pending, state => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(addFiscalData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fiscalData = action.payload;
    });
    builder.addCase(addFiscalData.rejected, state => {
      state.isLoading = false;
      state.isError = true;
    });
    // update fiscal data
    builder.addCase(updateFiscalData.pending, state => {
      state.isLoading = true;
      state.isError = false;
    });
    builder.addCase(updateFiscalData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fiscalData.fiscalDataType = action.payload.name;
      state.fiscalData.fiscalDataRegExp = action.payload.regex;
    });
    builder.addCase(updateFiscalData.rejected, state => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const { resetFiscalData } = fiscalDataSlice.actions;

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { type Currency, type BusinessAccounts } from '@/types';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';

const GridItem = styled.div<{ $cell?: [number?, number?]; $align?: string }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $align }) => $align ?? 'center'};
  align-content: center;
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;

export interface BusinessAccountRowProps {
  currency: Currency;
  businessAccount?: BusinessAccounts;
}

const BusinessAccountRow = ({ currency, businessAccount }: BusinessAccountRowProps): JSX.Element => {
  const { t } = useTranslation('configurations');

  return (
    <Fragment key={currency.currencyCode}>
      <GridItem $cell={[1, 1]}>
        <InputLabel label={t('business-accounts.currency-code')}>
          <Input
            value={currency.currencyCode}
            disabled
          />
        </InputLabel>
      </GridItem>
      <GridItem $cell={[2, 1]}>
        <InputLabel label={t('business-accounts.customer-id')}>
          <Input
            value={businessAccount?.customerId ?? ''}
            disabled
          />
        </InputLabel>
      </GridItem>
      <GridItem $cell={[3, 1]}>
        <InputLabel label={t('business-accounts.account-id')}>
          <Input
            value={businessAccount?.accountId ?? ''}
            disabled
          />
        </InputLabel>
      </GridItem>
    </Fragment>
  );
};

export default BusinessAccountRow;

import { createSlice } from '@reduxjs/toolkit';

import {
  type TransactionType,
  type LimitsResponse,
  TransactionTypeEnum,
  type LimitsByPeriod,
  type LimitsState,
  type PostResponse,
} from '@/types/Limits';
import { getLimitLevels, getLimits, searchChannels, updateLimit, createLimit } from './actions';
import { type Option } from '@/utils/interfaces';

export const defaultLimitsObjectByPeriodType: LimitsByPeriod = {
  byTransaction: undefined,
  daily: undefined,
  weekly: undefined,
  monthly: undefined,
  annualy: undefined,
  lifetime: undefined,
};

const initialState: LimitsState = {
  limitLevels: undefined,
  selectedLevel: undefined,
  limits: [],
  paymentToCustomerLimits: [],
  pToCChannels: [],
  transferLimits: [],
  transferChannels: [],
  topup: [],
  loading: false,
  success: false,
  errorFieldUpdate: false,
};

/**
 * Filter initial array of Limits by a given transaction type by returning an array from those types only that have a channel with it.
 *
 * @param limits
 * @param transactionType
 * @returns
 */
const filterLimitEntities = (limits: LimitsResponse[], transactionType: TransactionType): LimitsResponse[] => {
  const filteredLimits = limits.filter(limit => {
    if (limit.channel) {
      return limit.channel.transactionType === transactionType;
    }

    return false;
  });

  return filteredLimits;
};

export const limits = createSlice({
  name: 'limits',
  initialState,
  reducers: {
    setSelectedLimitLevel(
      state,
      action: {
        payload: Option | undefined;
      },
    ) {
      state.selectedLevel = action.payload;
    },
    reset() {
      return {
        limitLevels: undefined,
        selectedLevel: undefined,
        paymentToCustomerLimits: [],
        pToCChannels: [],
        limits: [],
        transferLimits: [],
        transferChannels: [],
        topup: [],
        loading: false,
        success: false,
        errorFieldUpdate: false,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(getLimitLevels.pending, state => {
      state.loading = true;
    });
    builder.addCase(getLimitLevels.fulfilled, (state, action) => {
      state.limitLevels = action.payload;
      state.loading = false;
      state.success = true;
    });
    builder.addCase(getLimitLevels.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    builder.addCase(getLimits.pending, state => {
      state.loading = true;
    });
    builder.addCase(getLimits.fulfilled, (state, action) => {
      state.limits = action.payload.limits;
      state.loading = false;
      state.success = true;
      state.paymentToCustomerLimits = filterLimitEntities(action.payload.limits, TransactionTypeEnum.PaymentToCustomer);
      state.transferLimits = filterLimitEntities(action.payload.limits, TransactionTypeEnum.Transfer);
      state.topup = filterLimitEntities(action.payload.limits, TransactionTypeEnum.Topup);
    });
    builder.addCase(getLimits.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    builder.addCase(searchChannels.pending, state => {
      state.loading = true;
    });
    builder.addCase(searchChannels.fulfilled, (state, action) => {
      if (action.payload.transactionType === TransactionTypeEnum.PaymentToCustomer) {
        state.pToCChannels = action.payload.data;
      } else if (action.payload.transactionType === TransactionTypeEnum.Transfer) {
        state.transferChannels = action.payload.data;
      }
      state.loading = false;
      state.success = true;
    });
    builder.addCase(searchChannels.rejected, state => {
      state.loading = false;
      state.success = false;
    });
    builder.addCase(updateLimit.pending, state => {
      state.loading = true;
    });
    builder.addCase(updateLimit.fulfilled, (state, action) => {
      if (action.payload.transactionType === TransactionTypeEnum.PaymentToCustomer) {
        state.paymentToCustomerLimits = state.paymentToCustomerLimits.map(limit =>
          limit.id === action.payload.limitId ? { ...limit, value: action.payload.data.value } : limit,
        );
      } else if (action.payload.transactionType === TransactionTypeEnum.Transfer) {
        state.transferLimits = state.transferLimits.map(limit =>
          limit.id === action.payload.limitId ? { ...limit, value: action.payload.data.value } : limit,
        );
      }

      state.loading = false;
      state.errorFieldUpdate = false;
    });
    builder.addCase(updateLimit.rejected, state => {
      state.loading = false;
      state.errorFieldUpdate = true;
    });
    builder.addCase(createLimit.pending, state => {
      state.loading = true;
    });
    builder.addCase(
      createLimit.fulfilled,
      (state, action: { payload: { data: PostResponse; transactionType: TransactionTypeEnum } }) => {
        if (action.payload.transactionType === TransactionTypeEnum.PaymentToCustomer) {
          const limitData = {
            ...action.payload.data.data[0],
            channel: {
              id: action.payload.data.data[0].channelId,
              name: state.pToCChannels[0].name,
              transactionType: action.payload.transactionType,
            },
          };
          state.paymentToCustomerLimits = [...state.paymentToCustomerLimits, limitData];
        } else if (action.payload.transactionType === TransactionTypeEnum.Transfer) {
          const limitData = {
            ...action.payload.data.data[0],
            channel: {
              id: action.payload.data.data[0].channelId,
              name: state.transferChannels[0].name,
              transactionType: action.payload.transactionType,
            },
          };
          state.transferLimits = [...state.transferLimits, limitData];
        }
        state.loading = false;
        state.errorFieldUpdate = false;
      },
    );
    builder.addCase(createLimit.rejected, state => {
      state.loading = false;
      state.errorFieldUpdate = true;
    });
  },
});

export const { reset, setSelectedLimitLevel } = limits.actions;

import { useCallback, useMemo, useEffect, type FC } from 'react';
import { Select } from 'k8-web-lib-tmp';
import { useTranslation } from 'react-i18next';

import { type Option } from '@/utils/interfaces';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import { setSelectedLimitLevel } from '@/lib/redux/slices/limits/slice';
import { getLimits } from '@/lib/redux/slices/limits/actions';
import { type LimitLevelType, LimitTypeEnum, MoneyFlowTypeEnum, LimitLevelEnum } from '@/types/Limits';
import { getPaymentMethodTopupByGroup as getPaymentMethodTopup } from '@/lib/redux/slices/payment-methods-topup/actions';
import { LimitLevelsContainer, LimitLevelsLabel } from './styles';

const acceptedLimitLevelsNames = [LimitLevelEnum.Standard, LimitLevelEnum.Entry];

const LimitLevels: FC = () => {
  const dispatch = useAppDispatch();
  const { loading, limitLevels, selectedLevel } = useAppSelector(state => state.limits);
  const { country } = useAppSelector(state => state.country);
  const { t } = useTranslation('configurations');

  useEffect(() => {
    if (limitLevels && limitLevels.length > 0) {
      const standardLevel = limitLevels.filter(limitLevel => limitLevel.name === acceptedLimitLevelsNames[0])[0];

      if (standardLevel) {
        dispatch(setSelectedLimitLevel({ text: standardLevel.name, value: standardLevel.id }));
      }
    }
  }, [limitLevels, dispatch]);

  useEffect(() => {
    if (selectedLevel && country?.countryCode) {
      void dispatch(
        getLimits({
          limitLevel: selectedLevel.text as LimitLevelType,
          limitType: LimitTypeEnum.Amount,
          moneyFlowType: MoneyFlowTypeEnum.MoneyIn,
          countryCode: country.countryCode,
        }),
      );
    }
  }, [selectedLevel, country, dispatch]);

  useEffect(() => {
    if (country?.countryCode) {
      void dispatch(
        getPaymentMethodTopup({
          countryCode: country.countryCode,
          groupId: null,
          limit: 100,
          offset: 0,
          includeTotal: false,
        }),
      );
    }
  }, [selectedLevel, country, dispatch]);

  const onSelect = useCallback(
    (option: Option) => {
      dispatch(setSelectedLimitLevel(option));
    },
    [dispatch],
  );

  const prepareLimitLevelsOptions = useMemo((): Option[] => {
    let limitLevelsOptions: Option[] = [];
    if (limitLevels) {
      limitLevelsOptions = limitLevels
        .filter(limitLevel => acceptedLimitLevelsNames.includes(limitLevel.name as LimitLevelEnum))
        .map(limitLevel => {
          return {
            text: limitLevel.name,
            value: limitLevel.id,
          };
        });
    }

    return limitLevelsOptions;
  }, [limitLevels]);

  return (
    <div>
      <LimitLevelsLabel>{t('limits.customer-level')}</LimitLevelsLabel>
      <LimitLevelsContainer>
        <Select
          options={prepareLimitLevelsOptions}
          selected={selectedLevel}
          onSelect={onSelect}
          loading={loading}
        />
      </LimitLevelsContainer>
    </div>
  );
};

export default LimitLevels;

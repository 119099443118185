import { type FC, type PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';

import Spinner from '@/components/common/spinners/Spinner';
import { type Permissions } from '@/types';
import { AppUrls } from '@/utils/constants';
import { usePermissions } from '@/utils/hooks/usePermissions';

type UserPermissionsGuardProps = Readonly<
  PropsWithChildren<{
    permission: Permissions;
    redirect?: string | boolean;
  }>
>;

const UserPermissionsGuard: FC<UserPermissionsGuardProps> = ({ children, permission, redirect }) => {
  const hasPermission = usePermissions(permission);
  const isPageLoading = hasPermission === undefined;
  const isRedirect = redirect !== undefined;

  if (isPageLoading && isRedirect) {
    return <Spinner />;
  }

  if (isPageLoading) {
    return null;
  }

  if (hasPermission) {
    return <>{children}</>;
  }

  if (isRedirect) {
    return <Navigate to={typeof redirect === 'string' ? redirect : AppUrls.NotFoundUrl} />;
  }

  return null;
};

export default UserPermissionsGuard;

import i18n from 'i18next';
import { toast } from 'react-toastify';

interface CustomToastProps {
  message: string;
}

export const successToast = (key?: string): void => {
  toast.success<CustomToastProps>(i18n.t(key ?? 'toast-messages.success'));
};

export const errorToast = (key?: string): void => {
  toast.error<CustomToastProps>(i18n.t(key ?? 'toast-messages.error'));
};

import { useEffect, useCallback, useMemo, useRef, type FC } from 'react';

import { Checkbox } from 'k8-web-lib-tmp';
import { useTranslation } from 'react-i18next';

import Button from '@/components/common/button/Button';
import Input from '@/components/common/inputs/Input';
import InputLabel from '@/components/common/labels/InputLabel';
import Modal from '@/components/common/modals/Modal';
import Typography from '@/components/common/typography/Typography';
import { useAppDispatch, useAppSelector } from '@/lib/redux';
import {
  createTopupPaymentMethodGroup,
  updateTopupPaymentMethodGroup,
} from '@/lib/redux/slices/payment-method-groups-topup/actions';
import { setModalData, closeAndResetModal } from '@/lib/redux/slices/payment-method-groups-topup/slice';
import type { PaymentMethodGroup } from '@/types/PaymentMethodGroup';
import { parseNumberOrDefault } from '@/utils/helperFunctions';

import styles from '../payment-method-topup/PaymentMethodTopupModal.module.scss';

type FormValues = PaymentMethodGroup[keyof PaymentMethodGroup];

const PaymentMethodGroupTopupModal: FC = () => {
  const { t } = useTranslation('configurations');
  const editedGroupCacheRef = useRef<PaymentMethodGroup>();

  const dispatch = useAppDispatch();
  const {
    mode,
    loading,
    data: paymentMethodGroup,
  } = useAppSelector(({ paymentMethodGroupTopup }) => paymentMethodGroupTopup.modal);

  useEffect(() => {
    return () => {
      dispatch(closeAndResetModal());
    };
  }, [dispatch]);

  const onClose = useCallback((): void => {
    dispatch(closeAndResetModal());
  }, [dispatch]);

  const onSubmit = useCallback((): void => {
    if (mode === 'opened-create') {
      void dispatch(createTopupPaymentMethodGroup(paymentMethodGroup));
      return;
    }

    void dispatch(updateTopupPaymentMethodGroup(paymentMethodGroup));
  }, [dispatch, paymentMethodGroup, mode]);

  const setPaymentMethodGroup = useCallback(
    (group: PaymentMethodGroup): void => {
      dispatch(setModalData(group));
    },
    [dispatch],
  );

  const isFormValid = useMemo(() => {
    const isValueValid = (value: FormValues): boolean => {
      switch (typeof value) {
        case 'undefined':
          return false;
        case 'string':
          return value.length > 0;
        case 'number':
          return value > 0;
        default:
          return true;
      }
    };

    if (mode === 'opened-create') {
      return Object.values(paymentMethodGroup).every(isValueValid);
    }

    if (mode === 'opened-edit') {
      // First data load, cache it for change checks
      if (editedGroupCacheRef.current === undefined) {
        editedGroupCacheRef.current = paymentMethodGroup;
        return false;
      }

      const hasValidChange = Object.entries(paymentMethodGroup).some(([key, value]) => {
        if (!editedGroupCacheRef.current) {
          return false;
        }

        const castedValue = value as FormValues;
        const cacheValue = editedGroupCacheRef.current[key as keyof PaymentMethodGroup];

        // Early return if values are identical
        if (castedValue === cacheValue) {
          return false;
        }

        // Handle string values
        if (typeof castedValue === 'string') {
          const trimmedValue = castedValue.trim();
          return trimmedValue !== cacheValue && isValueValid(trimmedValue);
        }

        // Handle non-string values
        return castedValue !== cacheValue && isValueValid(castedValue);
      });

      return hasValidChange;
    }

    // Clear change checks cached data
    editedGroupCacheRef.current = undefined;
    return false;
  }, [paymentMethodGroup, mode]);

  if (mode === 'closed') {
    return null;
  }

  const modalTitleKey = mode === 'opened-edit' ? 'title-update-group' : 'title-create-group';
  const submitButtonKey = mode === 'opened-edit' ? 'update-button' : 'create-button';

  return (
    <Modal onClose={onClose}>
      <div className={styles.title}>
        <Typography variant='p1SemiBold'>{t(`payment-method-topup.${modalTitleKey}`)}</Typography>
      </div>
      <div className={styles.container}>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.icon')}>
            <Input
              value={paymentMethodGroup.icon}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, icon: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.description-translation-key')}>
            <Input
              value={paymentMethodGroup.descriptionTranslationKey}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, descriptionTranslationKey: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.order')}>
            <Input
              value={String(paymentMethodGroup.order)}
              onChange={value =>
                setPaymentMethodGroup({
                  ...paymentMethodGroup,
                  order: parseNumberOrDefault(value, paymentMethodGroup.order),
                })
              }
            />
          </InputLabel>
        </div>
        <div className={styles.child}>
          <InputLabel label={t('payment-method-topup.name-translation-key')}>
            <Input
              value={paymentMethodGroup.nameTranslationKey}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, nameTranslationKey: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.country-code')}>
            <Input
              value={paymentMethodGroup.countryCode}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, countryCode: value })}
            />
          </InputLabel>
          <InputLabel label={t('payment-method-topup.reason')}>
            <Input
              value={paymentMethodGroup.reason}
              onChange={value => setPaymentMethodGroup({ ...paymentMethodGroup, reason: value })}
            />
          </InputLabel>
            <Checkbox
                checked={paymentMethodGroup.isEnabled}
                labelPosition={'left'}
                onChange={(value: boolean) => setPaymentMethodGroup({ ...paymentMethodGroup, isEnabled: value })}
              >
                <span className={styles.checkboxLabel}>{t('payment-method-topup.is-enabled')}</span>
            </Checkbox>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          disabled={!isFormValid || loading}
          textTransform='uppercase'
          onClick={onSubmit}
          data-testid={submitButtonKey}
        >
          {t(`payment-method-topup.${submitButtonKey}`)}
        </Button>
      </div>
    </Modal>
  );
};

export default PaymentMethodGroupTopupModal;

import { useCallback, useEffect, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/lib/redux';

import Input from '@/components/common/inputs/Input';
import {
  type LimitsByPeriodType,
  type LimitsRequestBodyData,
  MoneyFlowTypeEnum,
  LimitTypeEnum,
  TransactionTypeEnum,
  LimitsByPeriodTypeEnum,
  type LimitsByPeriod,
} from '@/types/Limits';
import { groupLimitsByPeriodType } from '@/utils/limits';
import { searchChannels, createLimit, updateLimit } from '@/lib/redux/slices/limits/actions';
import { Permissions } from '@/types';
import { usePermissions } from '@/utils/hooks/usePermissions';
import { parseNumberOrDefault } from '@/utils/helperFunctions';

import { GridItem, GridContainer } from './styles';

const PeerToPeerTransfer: FC = () => {
  const { t } = useTranslation('configurations');
  const [transferByPeriodType, setTransferByPeriodType] = useState<LimitsByPeriod>();
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const { transferLimits, selectedLevel, transferChannels, errorFieldUpdate } = useAppSelector(state => state.limits);
  const { countryCode } = useAppSelector(state => state.country.country);
  const hasCountryEditPermission = usePermissions(Permissions.countryConfigEdit);
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(searchChannels({ transactionType: TransactionTypeEnum.Transfer }));
  }, [dispatch]);

  useEffect(() => {
    if (transferLimits) {
      setTransferByPeriodType(groupLimitsByPeriodType(transferLimits));
    }
  }, [transferLimits]);

  useEffect(() => {
    if (errorFieldUpdate) {
      setHasChanged(true);
    }
  }, [errorFieldUpdate]);

  const handleInputBlur = useCallback(
    (periodType: LimitsByPeriodType) => {
      const limitData = transferByPeriodType?.[periodType];
      if (limitData && limitData?.value >= 0 && hasChanged) {
        if (limitData.id === undefined) {
          const requestBodyData: LimitsRequestBodyData = {
            limitConfigurations: [
              {
                channelId: transferChannels?.[0]?.id || '',
                limitLevel: selectedLevel?.text || '',
                periodType,
                value: Number(limitData.value) || 0,
                limitType: LimitTypeEnum.Amount,
                moneyFlowType: MoneyFlowTypeEnum.MoneyIn,
                countryCode,
              },
            ],
          };

          void dispatch(createLimit({ data: requestBodyData, transactionType: TransactionTypeEnum.Transfer }));
        } else {
          void dispatch(
            updateLimit({
              data: { value: Number(limitData?.value) },
              limitId: limitData?.id || '',
              transactionType: TransactionTypeEnum.Transfer,
            }),
          );
        }

        setHasChanged(false);
      }
    },
    [dispatch, countryCode, transferChannels, selectedLevel, transferByPeriodType, hasChanged],
  );

  const updateTransferLimit = useCallback(
    (periodType: LimitsByPeriodType, value: string) => {
      setTransferByPeriodType({
        ...transferByPeriodType,
        [periodType]: {
          ...transferByPeriodType?.[periodType],
          value: parseNumberOrDefault(value, transferByPeriodType?.[periodType]?.value || 0),
        },
      });

      setHasChanged(true);
    },
    [transferByPeriodType],
  );

  if (!transferByPeriodType) {
    return null;
  }

  return (
    <GridContainer>
      <div className='inner-container'>
        <GridItem $cell={[1, 2]}>{t('limits.transfer')}</GridItem>
        <GridItem $cell={[3, 1]}>
          <Input
            onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.ByTransaction, value)}
            value={transferByPeriodType?.byTransaction?.value.toString() || ''}
            onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.ByTransaction)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
        <GridItem $cell={[4, 1]}>
          <Input
            onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.Daily, value)}
            value={transferByPeriodType?.daily?.value.toString() || ''}
            onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.Daily)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
        <GridItem $cell={[5, 1]}>
          <Input
            onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.Weekly, value)}
            value={transferByPeriodType?.weekly?.value.toString() || ''}
            onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.Weekly)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
        <GridItem $cell={[6, 1]}>
          <Input
            onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.Monthly, value)}
            value={transferByPeriodType?.monthly?.value.toString() || ''}
            onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.Monthly)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
        <GridItem $cell={[7, 1]}>
          <Input
            onChange={(value: string) => updateTransferLimit(LimitsByPeriodTypeEnum.Lifetime, value)}
            value={transferByPeriodType?.lifetime?.value.toString() || ''}
            onBlur={() => handleInputBlur(LimitsByPeriodTypeEnum.Lifetime)}
            disabled={!hasCountryEditPermission}
          />
        </GridItem>
      </div>
    </GridContainer>
  );
};

export default PeerToPeerTransfer;

import { type FC, type PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';

import { featureFlagsData, type FeatureFlagKeys } from '@/types';
import { AppUrls } from '@/utils/constants';

type FeatureFlagGuardProps = Readonly<
  PropsWithChildren<{
    featureFlag: FeatureFlagKeys;
    redirect?: string | boolean;
  }>
>;

const FeatureFlagGuard: FC<FeatureFlagGuardProps> = ({ featureFlag, children, redirect }) => {
  if (featureFlagsData[featureFlag]) {
    return <>{children}</>;
  }

  const shouldRedirect = redirect !== undefined;
  if (shouldRedirect) {
    return <Navigate to={typeof redirect === 'string' ? redirect : AppUrls.NotFoundUrl} />;
  }

  return null;
};

export default FeatureFlagGuard;

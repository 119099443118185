import { useState, useEffect, useCallback } from 'react';
import ActionModal from '@/components/customer/kyc-risk/audit-logs/ActionModal/ActionModal';
import { useTranslation } from 'react-i18next';
import RefundModalInputs from './RefundModalInputs';
import { type RootState, useAppSelector, useAppDispatch } from '@/lib/redux';
import { walletRefunds } from '@/lib/redux/slices/wallet-refunds/action';
import { reset as resetWalletRefunds } from '@/lib/redux/slices/wallet-refunds/slice';
import { fetchTransactions } from '@/lib/redux/slices/transaction-history/actions';
import { type Option } from '@/utils/interfaces';
import useItemsPerPage from '@/utils/hooks/useItemsPerPage';
import { fetchAccounts } from '@/lib/redux/slices/accounts/actions';

export interface Refund {
  reason: string;
  transactionType: Option;
  currency: Option;
  requestedAmountNumber: number;
  requestedAmountString: string;
}

const initialState = {
  reason: '',
  transactionType: { text: '', value: '' },
  currency: { text: '', value: '' },
  requestedAmountNumber: 0,
  requestedAmountString: '',
};

const RefundModal = ({
  open,
  onClose,
  customerId,
}: {
  open: boolean;
  onClose: () => void;
  customerId: string;
}): JSX.Element => {
  const { t } = useTranslation(['customerOverview', 'global']);

  const dispatch = useAppDispatch();

  const { success, loading } = useAppSelector((state: RootState) => state.walletRefunds);
  const [itemsPerPage] = useItemsPerPage();

  const [refund, setRefund] = useState<Refund>(initialState);

  const handleClose = useCallback(() => {
    onClose();
    setRefund(initialState);
  }, [onClose]);

  useEffect(() => {
    if (success) {
      handleClose();
      void dispatch(resetWalletRefunds());
      void dispatch(fetchTransactions({ customerId, page: 1, itemsPerPage }));
      dispatch(fetchAccounts(customerId)).then(
        () => {},
        () => {},
      );
    }
  }, [dispatch, success, handleClose, customerId, itemsPerPage]);

  const handleConfirm = (): void => {
    void dispatch(
      walletRefunds({
        amount: refund.requestedAmountNumber,
        reason: refund.reason,
        transactionType: refund.transactionType.value ?? '',
        accountId: refund.currency.value ?? '',
      }),
    );
  };

  const isNotFilled =
    refund.reason.trim().length === 0 ||
    refund.requestedAmountNumber === 0 ||
    refund.currency.value?.length === 0 ||
    refund.transactionType.value?.length === 0;

  return (
    <>
      {open && (
        <ActionModal
          title={t('widgets.transaction-history.refund-modal.refund-label')}
          titleVariant='p0'
          disabledButton={isNotFilled || loading}
          confirmButtonLabel={t('confirm-modal.confirm', { ns: 'global' })}
          closeButtonLabel={t('confirm-modal.cancel', { ns: 'global' })}
          onConfirm={handleConfirm}
          onClose={handleClose}
        >
          <RefundModalInputs
            refund={refund}
            setRefund={setRefund}
          />
        </ActionModal>
      )}
    </>
  );
};

export default RefundModal;

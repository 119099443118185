import authAxios from '@/auth/axios';
import { type BusinessAccounts } from '@/types';
import { errorToast, successToast } from '@/utils/toastMessage';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCountryBusinessAccounts = createAsyncThunk(
  'countryBusinessAccounts.getCountryBusinessAccounts',
  async ({ countryCode }: { countryCode: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.get<{ data: BusinessAccounts[] }>('/payments/merchant-accounts', {
        params: {
          countryCode,
        },
      });
      return data.data;
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const createBusinessAccounts = createAsyncThunk(
  'countryBusinessAccounts.createBusinessAccounts',
  async ({ countryCode, integratorId }: { countryCode: string; integratorId: string }, { rejectWithValue }) => {
    try {
      const { data } = await authAxios.post<{ data: BusinessAccounts[] }>(
        '/payments/merchant-accounts',
        {
          integratorId,
        },
        {
          params: {
            countryCode,
          },
        },
      );
      successToast('toast-messages.business-accounts-success');
      return data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

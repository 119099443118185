import { defaultLimitsObjectByPeriodType } from '@/lib/redux/slices/limits/slice';
import { PeriodTypeEnum, type LimitsResponse, type LimitsByPeriod } from '@/types/Limits';

export const groupLimitsByPeriodType = (limits: LimitsResponse[]): LimitsByPeriod => {
  const limitsByPeriodType: LimitsByPeriod = { ...defaultLimitsObjectByPeriodType };

  for (let i = 0; i < limits.length; i++) {
    if (limits[i].periodType === PeriodTypeEnum.ByTransaction) {
      limitsByPeriodType.byTransaction = limits[i];
    } else if (limits[i].periodType === PeriodTypeEnum.Daily) {
      limitsByPeriodType.daily = limits[i];
    } else if (limits[i].periodType === PeriodTypeEnum.Weekly) {
      limitsByPeriodType.weekly = limits[i];
    } else if (limits[i].periodType === PeriodTypeEnum.Monthly) {
      limitsByPeriodType.monthly = limits[i];
    } else if (limits[i].periodType === PeriodTypeEnum.Annual) {
      limitsByPeriodType.annualy = limits[i];
    } else if (limits[i].periodType === PeriodTypeEnum.Lifetime) {
      limitsByPeriodType.lifetime = limits[i];
    }
  }

  return limitsByPeriodType;
};

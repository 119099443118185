import { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@/lib/redux';
import InputLabel from '@/components/common/labels/InputLabel';
import Input from '@/components/common/inputs/Input';
import Accordion from '@/components/common/accordion/Accordion';
import { AccordionTitle } from '@/components/configurations';
import Button from '@/components/common/button/Button';

import styles from './LimitsCurrencyMultiplier.module.scss';
import UserPermissionsGuard from '@/components/user-permissions/UserPermissionsGuard';
import { Permissions } from '@/types';
import {
  createCurrencyMultiplier,
  updateCurrencyMultiplier,
} from '@/lib/redux/slices/limits-currency-multiplier/actions';

const GridItem = styled.div<{ $cell?: [number?, number?]; $align?: string }>`
  grid-column: ${({ $cell = [1, 1] }) => {
    const start = $cell[0];
    const span = $cell[1] ?? 1;
    return `${start} / span ${span}`;
  }};
  display: flex;
  flex-direction: row;
  justify-content: ${({ $align }) => $align ?? 'center'};
  align-content: center;
  @media (max-width: 1090px) {
    grid-column: 1 / span 5;
  }
`;

export interface LimitsCurrencyMultiplierProps {
  canEdit: boolean;
}

const LimitsCurrencyMultiplier = ({ canEdit }: Readonly<LimitsCurrencyMultiplierProps>): JSX.Element => {
  const { t } = useTranslation('configurations');
  const dispatch = useAppDispatch();

  const { country } = useAppSelector(state => state.country);
  const { currencyMultiplier, loading } = useAppSelector(state => state.limitsCurrencyMultiplier);
  const [newCurrencyMultiplier, setNewCurrencyMultiplier] = useState(currencyMultiplier);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (currencyMultiplier !== null) {
      setNewCurrencyMultiplier(currencyMultiplier);
    }
  }, [currencyMultiplier]);

  const localCurrencyCode = useMemo(() => {
    return country.currencies.find(c => c.isLocal)?.currencyCode ?? '';
  }, [country.currencies]);

  const onSubmit = (): void => {
    if (newCurrencyMultiplier !== null) {
      if (currencyMultiplier === null) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        dispatch(createCurrencyMultiplier({ currencyCode: localCurrencyCode, multiplier: newCurrencyMultiplier }));
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        dispatch(
          updateCurrencyMultiplier({
            currencyCode: localCurrencyCode,
            multiplier: newCurrencyMultiplier,
          }),
        );
      }
    }
  };

  const checkSubmitDisabled = useMemo((): boolean => {
    if (loading) {
      return true;
    }

    if (localCurrencyCode === '') {
      return true;
    }

    return newCurrencyMultiplier === undefined || newCurrencyMultiplier === currencyMultiplier;
  }, [loading, localCurrencyCode, currencyMultiplier, newCurrencyMultiplier]);

  const renderContent = (
    <div className={styles.card}>
      <div className={styles.gridCard}>
        <GridItem $cell={[1, 1]}>
          <InputLabel label={t('limits-currency-multiplier.currency-code')}>
            <Input
              value={localCurrencyCode}
              disabled={true}
            />
          </InputLabel>
        </GridItem>
        <GridItem $cell={[2, 1]}>
          <InputLabel label={t('limits-currency-multiplier.multiplier')}>
            <Input
              value={newCurrencyMultiplier != null ? String(newCurrencyMultiplier) : ''}
              type='number'
              onChange={(value: string) => setNewCurrencyMultiplier(Number(value))}
              disabled={!canEdit}
            />
          </InputLabel>
        </GridItem>
      </div>
      <UserPermissionsGuard permission={Permissions.countryConfigEdit}>
        <div className={styles.buttons}>
          <Button
            textTransform='uppercase'
            disabled={checkSubmitDisabled}
            onClick={() => onSubmit()}
          >
            {t('new-country.button-submit-configuration')}
          </Button>
        </div>
      </UserPermissionsGuard>
    </div>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          title={t('limits-currency-multiplier.title')}
          isValid={currencyMultiplier != null && checkSubmitDisabled}
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => setActive(!active)}
    />
  );
};

export default LimitsCurrencyMultiplier;

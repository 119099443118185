import { createAsyncThunk } from '@reduxjs/toolkit';
import { type AxiosResponse } from 'axios';

import authAxios from '@/auth/axios';
import { errorToast, successToast } from '@/utils/toastMessage';
import type { PaymentMethodGroup, CreateResponse, UpdateResponse } from '@/types/PaymentMethodGroup';

interface PaymentMethodGroupsResponse {
  data: PaymentMethodGroup[];
}

export const getPaymentMethodGroupTopup = createAsyncThunk(
  'paymentMethodGroupTopup.getData',
  async (countryCode: string, { rejectWithValue }) => {
    try {
      const response = await authAxios.get<PaymentMethodGroupsResponse>('/topup/payment-method-groups', {
        params: {
          countryCode,
          includeDisabled: true,
          showRelativeBlobUrls: true,
        },
      });

      return response.data.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const createTopupPaymentMethodGroup = createAsyncThunk(
  'paymentMethodGroupTopup.create',
  async (data: PaymentMethodGroup, { rejectWithValue }) => {
    try {
      const response = await authAxios.post<CreateResponse, AxiosResponse<CreateResponse>, PaymentMethodGroup>(
        '/topup/payment-method-groups',
        {
          ...data,
          countryCode: data.countryCode.toUpperCase(),
        },
      );

      successToast('toast-messages.payment-method-created');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

export const updateTopupPaymentMethodGroup = createAsyncThunk(
  'paymentMethodGroupTopup.update',
  async ({ id, ...data }: PaymentMethodGroup, { rejectWithValue }) => {
    try {
      const response = await authAxios.put<UpdateResponse, AxiosResponse<UpdateResponse>, PaymentMethodGroup>(
        `/topup/payment-method-groups/${id}`,
        {
          ...data,
          countryCode: data.countryCode.toUpperCase(),
        },
      );

      successToast('toast-messages.payment-method-updated');

      return response.data;
    } catch (err: any) {
      errorToast();
      return rejectWithValue(err);
    }
  },
);

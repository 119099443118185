import { useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

import Accordion from '@/components/common/accordion/Accordion';
import AccordionTitle from './MoneyInAccordeonTitle';
import PaymentToCustomer from './PaymentToCustomer';
import TopUp from './TopUp';

import { GridContainer, GridContainerHeader, GridItem } from './styles';
import PeerToPeerTransfer from './PeerToPeerTransfer';

const MoneyIn: FC = () => {
  const { t } = useTranslation('configurations');
  const [active, setActive] = useState<boolean>(false);

  const renderContent = (
    <>
      <GridContainerHeader>
        <GridItem $cell={[1, 2]} />
        <GridItem $cell={[3, 1]}>{t('limits.per-transaction')}</GridItem>
        <GridItem $cell={[4, 1]}>{t('limits.daily')}</GridItem>
        <GridItem $cell={[5, 1]}>{t('limits.weekly')}</GridItem>
        <GridItem $cell={[6, 1]}>{t('limits.monthly')}</GridItem>
        <GridItem $cell={[7, 1]}>{t('limits.lifetime')}</GridItem>
      </GridContainerHeader>
      <PaymentToCustomer />
      <PeerToPeerTransfer />
      <GridContainer>
        <div className='inner-container'>
          <GridItem $cell={[1, 2]}>{t('limits.top-up')}</GridItem>
        </div>
      </GridContainer>
      <TopUp />
    </>
  );

  return (
    <Accordion
      title={
        <AccordionTitle
          isValid={true}
          title={t('limits.money-in-title')}
        />
      }
      content={renderContent}
      isActive={active}
      onClick={() => {
        setActive(!active);
      }}
    />
  );
};

export default MoneyIn;

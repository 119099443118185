import React, { useState, useRef, useEffect, type ReactNode } from 'react';
import classNames from 'classnames';
import ArrowIcon from '@/components/icons/ArrowIcon';
import styles from './Accordion.module.scss';
import Spinner from '../spinners/Spinner';

interface AccordionProps {
  title: ReactNode;
  content: ReactNode;
  isActive: boolean;
  isLoading?: boolean;
  onClick: () => void;
}

const Accordion: React.FC<AccordionProps> = ({ title, content, isActive, isLoading = false, onClick }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number | null>(0);
  const arrowClasses = classNames(styles.arrow, {
    [styles['arrow--opened']]: isActive,
  });

  const contentClasses = classNames(styles.accordionContent, {
    [styles['accordionContent--active']]: isActive,
  });

  useEffect(() => {
    if (isActive && contentRef.current) {
      setMaxHeight(contentRef.current.scrollHeight);
    } else {
      setMaxHeight(0);
    }
  }, [isActive, content]);

  return (
    <div>
      <div className={styles.accordionItem}>
        {isLoading && (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        )}
        <div
          onClick={onClick}
          onKeyDown={onClick}
          className={styles.accordionHeader}
        >
          {title}
          <span className={arrowClasses}>
            <ArrowIcon />
          </span>
        </div>

        <div
          ref={contentRef}
          className={contentClasses}
          style={{ maxHeight: isActive ? `${maxHeight}px` : '0px' }}
        >
          <div className={styles.content}>{content}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;

import { useMemo, type FC } from 'react';
import { useAppSelector } from '@/lib/redux';
import { type PaymentMethod } from '@/types/PaymentMethod';
import { GridContainerTable, GridRow, GridCell } from './styles';
import TopUpInput from './TopUpInput';
import { useTranslation } from 'react-i18next';

const TopUp: FC = () => {
  const { t } = useTranslation('configurations');
  const { topup } = useAppSelector(state => state.limits);
  const { paymentMethods } = useAppSelector(state => state.paymentMethodTopup);

  const updatedArray = useMemo(() => {
    return paymentMethods.reduce<PaymentMethod[]>((result, item) => {
      const matchingLimits = topup.filter(limit => limit.channel.id === item.channelId);
      const updatedItem = {
        ...item,
        limits: matchingLimits.length > 0 ? matchingLimits.map(limit => ({ ...limit })) : [],
      };
      result.push(updatedItem);
      return result;
    }, []);
  }, [paymentMethods, topup]);

  return (
    <GridContainerTable>
      {updatedArray.map(method => (
        <GridRow key={method.id}>
          <GridCell
            $start={1}
            $span={7}
          >
            {method.name}
          </GridCell>
          {method?.limits && method?.descriptionTranslationKey === 'InstantAfterPaymentMethodDescription' && (
            <GridCell
              $start={1}
              $span={7}
            >
              <TopUpInput
                type={t('limits.cash')}
                limits={method.limits}
                method={method}
              />
            </GridCell>
          )}
          {method?.descriptionTranslationKey !== 'InstantAfterPaymentMethodDescription' && (
            <GridCell
              $start={1}
              $span={7}
            >
              <TopUpInput
                type={t('limits.online')}
                limits={method.limits}
                method={method}
              />
            </GridCell>
          )}
        </GridRow>
      ))}
    </GridContainerTable>
  );
};

export default TopUp;

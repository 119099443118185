import { useEffect, type FC } from 'react';

import { useAppDispatch } from '@/lib/redux';
import { getLimitLevels } from '@/lib/redux/slices/limits/actions';
import { reset } from '@/lib/redux/slices/limits/slice';
import LimitLevels from '@/components/configurations/limits/LimitLevels';
import MoneyIn from '@/components/configurations/limits/MoneyIn';

import { LimitsContainer } from './styles';

const Limits: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(getLimitLevels());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return (
    <LimitsContainer>
      <LimitLevels />
      <MoneyIn />
    </LimitsContainer>
  );
};

export default Limits;
